


























































































































































































































































































































































































































































































































































































































































































































































































































































import { mapGetters, mapActions, mapState } from "vuex";
import axios from "axios";
import "bootstrap-vue";
import Vue from "vue";
import Component from "vue-class-component";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import CabinetHeader from "./CabinetHeader.vue";
import store from "@/services/store";
import VueElementLoading from "vue-element-loading";

export interface IMessage {
    id: number;
    sendDate: string;
    sendTime: string;
    title: string;
    module: string;
    text: string;
    dedline?: string;
    status: number;
}

interface IncomeMessages extends IMessage {
    sender: string;
    fullDate: Date;
}

interface OutMessages extends IMessage {
    receiver: string;
    createDateTime: any;
}

interface SelectedMessage extends IMessage {
    sender?: string;
    receiver?: string;
}
interface ITheme {
    id: number;
    nameRu: string;
    nameKk: string;
    nameEn: string;
}
interface IModule {
    code: string;
    name_ru: string;
    name_kk?: string;
    text: string;
}
interface INewMessage {
    receiver: string;
    theme: ITheme | null;
    module?: IModule | null;
    messageText: string;
    dedline: string | null;
    link?: string | null;
}

interface IReceiver {
    id: string;
    firstName: string;
    lastName: string;
    abp?: string;
    gu?: string;
    main?: boolean;
    kgkp?: string;
    region?: string;
    isSupport: boolean;
    check: boolean;
}

@Component({
    name: "notifications",
    components: {
        DatePicker,
        multiselect: Multiselect,
        CabinetHeader,
        loading: VueElementLoading,
    },
    computed: {
        ...mapState("notifications", {
            vuexMessages: (state: any) => state.messages,
        }),
        isShowPopUp: {
            get(): boolean {
                return this.$store.state.notifications.isShowPopUp;
            },
            set(value: boolean): void {
                this.$store.commit("notifications/setIsShowPopUp", value);
            },
        },
        ...mapGetters("notifications", ["outMessages"]),
    },
    methods: {
        ...mapActions("notifications", [
            "fetchMessages",
            "changeStatus",
            "fetchOutMessages",
            "fetchStatusShowPopUp",
            "toggleShowPopUp",
        ]),
    },
})
export default class Notification extends Vue {
    countUnreadNoty: number = 0;
    statusMessages: number = 0;
    showAllNames: boolean = false;
    private isSendEmail: boolean = false;
    public isShowPopUp!: boolean;
    curTab = 0;
    selectedMessage: SelectedMessage | null = null;
    public modalForm: boolean = false;
    isModalVisible: boolean = false;
    private allMessages: IncomeMessages[] = [];
    public unreadMessages: IncomeMessages[] = [];
    messageList: IncomeMessages[] = [];
    sentMesList: OutMessages[] = [];
    barLoading: number = 0;
    isLoading: boolean = false;

    private startDate: Date = (() => {
        const today = new Date();
        today.setMonth(today.getMonth() - 1);
        return today;
    })();
    private endDate: Date = new Date();

    private formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    private formatDateFromBack(dateString: string): Date | null {
        if (dateString === "null") return null;

        const dateParts = dateString.split(" ")[0].split("-");
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1;
        const day = parseInt(dateParts[2], 10);

        return new Date(year, month, day);
    }

    selectTitle = [];
    selectModule = [];
    receiverModal: boolean = false;
    receivers: IReceiver[] = [];
    selectedIds: string[] = [];

    selectAll: boolean = false;
    selectGu: boolean = false;
    selectMainGu: boolean = false;
    selectKgkp: boolean = false;
    selectSupport: boolean = false;
    selectAllFiltered: boolean = false;
    searchName: string = "";
    searchAbp: string = "";
    searchRegion: string = "";
    searchGu: string = "";
    searchKgkp: string = "";
    newMessage: INewMessage = {
        receiver: "",
        theme: null,
        module: null,
        messageText: "",
        dedline: null,
        link: null,
    };
    private specialistData: IReceiver[] = [];
    private supportData: IReceiver[] = [];

    // private perPage: number = 50;
    // private curPage: number = 1;
    // private itemCount: number = 0;
    // private pageCount: number = 0;
    // private rows: number = 0;
    private showInfoModal: boolean = false;
    private selectedReceiver: IReceiver | null = null;
    private selReceivers: IReceiver[] = [];

    private async loadReceivedMessages() {
        let queryParams: any = {};
        try {
            this.allMessages = [];
            let response;
            if (this.startDate && this.endDate) {
                queryParams = {
                    begdate: this.formatDate(this.startDate),
                    enddate: this.formatDate(this.endDate),
                };
                response = await axios.get("/api/notify/user/listbetween", {
                    params: queryParams,
                });
            } else {
                response = await axios.get("/api/notify/user/list");
            }
            this.allMessages = response.data.map((message: any) => {
                const formattedCreateDate = this.formatDateFromBack(
                    message.createDate
                );
                const formattedEndDate = this.formatDateFromBack(
                    message.endDate
                );

                return {
                    id: message.id,
                    sendDate: formattedCreateDate
                        ? formattedCreateDate.toLocaleDateString("ru-RU")
                        : "",
                    sendTime: message.createDate
                        .split(" ")[1]
                        .split(":")
                        .slice(0, 2)
                        .join(":"),
                    title: message.theme,
                    module: message.module_name,
                    text: message.msg,
                    dedline: formattedEndDate
                        ? formattedEndDate.toLocaleDateString("ru-RU")
                        : "",
                    sender: message.userName,
                    status: message.status,
                    isOutgoing: false,
                    fullDate: new Date(message.createDate),
                };
            });
            // this.allMessages.sort((a, b) => b.fullDate.getTime() - a.fullDate.getTime());
            this.messageList = this.allMessages;
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при настройке отправки уведомлений на почту: ",
                    error.message
                );
            }
        }
    }

    public async loadUnreadMessages() {
        let queryParams: any = {};
        try {
            this.unreadMessages = [];
            let response;
            if (this.startDate && this.endDate) {
                queryParams = {
                    begdate: this.formatDate(this.startDate),
                    enddate: this.formatDate(this.endDate),
                };
                response = await axios.get("/api/notify/user/unread-list", {
                    params: queryParams,
                });
            } else {
                response = await axios.get("/api/notify/user/unread-list");
            }
            this.unreadMessages = response.data.map((message: any) => {
                const formattedCreateDate = this.formatDateFromBack(
                    message.createDate
                );
                const formattedEndDate = this.formatDateFromBack(
                    message.endDate
                );

                return {
                    id: message.id,
                    sendDate: formattedCreateDate
                        ? formattedCreateDate.toLocaleDateString("ru-RU")
                        : "",
                    sendTime: message.createDate
                        .split(" ")[1]
                        .split(":")
                        .slice(0, 2)
                        .join(":"),
                    title: message.theme,
                    module: message.module_name,
                    text: message.msg,
                    dedline: formattedEndDate
                        ? formattedEndDate.toLocaleDateString("ru-RU")
                        : "",
                    sender: message.userName,
                    status: message.status,
                    isOutgoing: false,
                    fullDate: new Date(message.createDate),
                };
            });
            this.unreadMessages.sort(
                (a, b) => b.fullDate.getTime() - a.fullDate.getTime()
            );
            this.messageList = this.unreadMessages;
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при загрузке не прочитанных уведомлений",
                    error.toString()
                );
            }
        }
    }

    async showModal() {
        this.isModalVisible = true;
        try {
            await Promise.all([
                this.loadDictTitle(),
                this.loadDictModule(),
                this.loadReceiversList(),
            ]);
        } catch (error) {
            console.error(
                "Ошибка при загрузке данных для списка получателей уведомлений",
                error
            );
        }
    }

    async openMessageModal(item: any) {
        this.selectedMessage = item;
        this.modalForm = true;

        if (!item.isOutgoing) {
            const id = item.id;
            const status = 4;
            // await this.changeStatus(id, status );
            try {
                await this.$store.dispatch("notifications/changeStatus", {
                    id,
                    status,
                });
                await this.updateMessages();
            } catch (error) {
                console.error("Error changing message status:", error);
            }
        }
    }

    private async loadSendedMessages() {
        this.barLoading = 0;
        let queryParams: any = {};
        try {
            let response;
            this.sentMesList = [];
            await this.animateProgress(30, 500);
            if (this.startDate && this.endDate) {
                queryParams = {
                    begdate: this.formatDate(this.startDate),
                    enddate: this.formatDate(this.endDate),
                };
                response = await axios.get("/api/notify/user/listmebetween", {
                    params: queryParams,
                });
            } else {
                response = await axios.get("/api/notify/user/listme");
            }
            await this.animateProgress(60, 500);
            const grouppedMessages = response.data.reduce(
                (acc: any, message: any) => {
                    const { group_id, userName, ...rest } = message;
                    if (!acc[group_id]) {
                        acc[group_id] = { ...rest, group_id, recipients: [] };
                    }
                    acc[group_id].recipients.push(userName);
                    return acc;
                },
                {}
            );
            this.sentMesList = Object.values(grouppedMessages).map(
                (item: any) => {
                    const formattedCreateDate = this.formatDateFromBack(
                        item.createDate
                    );
                    const formattedEndDate = this.formatDateFromBack(
                        item.endDate
                    );
                    return {
                        id: item.id,
                        sendDate: formattedCreateDate
                            ? formattedCreateDate.toLocaleDateString("ru-Ru")
                            : "",
                        sendTime: item.createDate
                            .split(" ")[1]
                            .split(":")
                            .slice(0, 2)
                            .join(":"),
                        title: item.theme,
                        module: item.module_name,
                        text: item.msg,
                        dedline: formattedEndDate
                            ? formattedEndDate.toLocaleDateString("ru-RU")
                            : "",
                        receiver: item.recipients.join(", "), // Объед. имен получателей для исход сообщений
                        status: item.status,
                        isOutgoing: true,
                        createDateTime: new Date(
                            item.createDate.replace(" ", "T")
                        ),
                    };
                }
            );
            this.sentMesList.sort(
                (a, b) => b.createDateTime - a.createDateTime
            );
            await this.animateProgress(90, 500);
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при загрузке уведомлений",
                    error.toString()
                );
            }
        } finally {
            await this.animateProgress(100, 500);
        }
    }

    private animateProgress(targetValue: number, duration: number) {
        return new Promise<void>((resolve) => {
            const startValue = this.barLoading;
            const startTime = performance.now();

            const step = (currentTime: number) => {
                const elapsed = currentTime - startTime;
                const progress = Math.min(elapsed / duration, 1);
                this.barLoading =
                    startValue + (targetValue - startValue) * progress;

                if (progress < 1) {
                    requestAnimationFrame(step);
                } else {
                    resolve();
                }
            };
            requestAnimationFrame(step);
        });
    }

    public async setStatusUnread(item: any) {
        this.selectedMessage = item;
        const id = item.id;
        const status = 1;
        await this.$store.dispatch("notifications/changeStatus", {
            id,
            status,
        });
        await this.updateMessages();
    }

    public async addNewMessage() {
        const selectedUsers = [...this.selectedIds];
        if (!this.newMessage.theme) {
            this.makeToast(
                "warning",
                "Не выбрана тема сообщения!",
                "Внимание!"
            );
            return;
        }
        if (selectedUsers.length === 0) {
            this.makeToast("warning", "Не выбран получатель!", "Внимание!");
            return;
        }
        if (!this.newMessage.messageText) {
            this.makeToast(
                "warning",
                "Сообщение не может быть пустым!",
                "Внимание!"
            );
            return;
        }

        const messageTextLimit = 5000;
        const messageTextLength = Array.from(
            this.newMessage.messageText
        ).length;

        if (messageTextLength > messageTextLimit) {
            this.makeToast(
                "warning",
                `Длина сообщения превышает допустимый лимит в ${messageTextLimit} символов!`,
                "Внимание!"
            );
            return;
        }

        const payload = {
            users: selectedUsers,
            theme_id: this.newMessage.theme ? this.newMessage.theme.id : null,
            module_code: this.newMessage.module
                ? this.newMessage.module.code
                : null,
            msg: this.newMessage.messageText,
            endDate: this.newMessage.dedline,
            link: this.newMessage.link,
        };

        try {
            const response = await axios.post(
                "/api/notify/user/add-list",
                payload
            );
            if (response.status === 200) {
                this.$emit("messageSent", response.data);
                this.makeToast("success", "Сообщение отправлено", "Сообщение");
            } else {
                this.makeToast(
                    "danger",
                    "Ошибка при отправке сообщения",
                    "Ошибка"
                );
            }
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при отправке нового сообщения",
                    error.toString()
                );
            }
        }
        this.resetNewMessage();
        this.isModalVisible = false;
        await this.updateMessages();
        // await this.$store.dispatch("notifications/fetchMessages");
    }

    public async loadDictTitle() {
        try {
            const response = await fetch("/api/dict/notify_theme");
            const titles = await response.json();
            this.selectTitle = titles.map((title: any) => ({
                ...title,
                nameRu:
                    title.nameRu.charAt(0).toUpperCase() +
                    title.nameRu.slice(1),
            }));
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса темы",
                    error.toString()
                );
            }
        }
    }

    async loadDictModule() {
        try {
            const response = await fetch("/api-py/list_of_modules");
            let modules = await response.json();
            modules = modules.filter(
                (module: IModule) => module.code !== "all"
            );
            this.selectModule = modules;
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса модуля",
                    error.toString()
                );
            }
        }
    }

    // async fetchSupportData() {
    //     try {
    //         // this.selectReceiver = [];
    //         const response = await axios.get(`/api/um/list-user-support`);
    //         const supportResponse = response;
    //         this.supportData = supportResponse.data.map((user: IReceiver) => {
    //             user.isSupport = true;
    //             return user;
    //         });
    //         this.receivers = this.supportData;
    //     } catch (error) {
    //         if (error instanceof Error) {
    //             this.makeToast(
    //                 "danger",
    //                 "Ошибка запроса списка Техподдержки",
    //                 error.toString()
    //             );
    //         }
    //     }
    // }

    // async fetchSpecialistData() {
    //     try {
    //         // this.selectReceiver = [];
    //         const response = await axios.get(`/api/um/dependency-users-list`);
    //         const specResponse = response;
    //         this.specialistData = specResponse.data.map((user: IReceiver) => {
    //             user.isSupport = false;
    //             return user;
    //         });
    //         this.receivers = this.specialistData;
    //     } catch (error) {
    //         if (error instanceof Error) {
    //             this.makeToast(
    //                 "danger",
    //                 "Ошибка запроса списка Специалистов ГУ",
    //                 error.toString()
    //             );
    //         }
    //     }
    // }

    async openUserInfoModal(item: any) {
        this.selReceivers = this.receivers.filter((r) => r.id === item.id);
        if (this.selReceivers.length > 0) {
            this.showInfoModal = true;
            this.selectedReceiver = item;
        }
    }

    async loadReceiversList() {
        try {
            this.isLoading = true;
            // Загрузка списка специалистов техподдержки
            const supportResponse = await fetch("/api/um/list-user-support");
            const supportList = await supportResponse.json();
            supportList.forEach((user: IReceiver) => (user.isSupport = true));

            // Загрузка списка всех получателей кроме ТП
            const receiversResponse = await fetch(
                "/api/um/dependency-users-list"
            );
            const receiversList = await receiversResponse.json();
            receiversList.forEach(
                (user: IReceiver) => (user.isSupport = false)
            );

            const combinedList = [...supportList, ...receiversList];
            this.receivers = combinedList.sort((a, b) => {
                return b.isSupport - a.isSupport;
            });
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса списка получателей",
                    error.toString()
                );
            }
        } finally {
            this.isLoading = false;
        }
    }

    private resetNewMessage() {
        this.newMessage = {
            receiver: "",
            theme: null,
            module: null,
            messageText: "",
            dedline: null,
            link: null,
        };
        this.resetSelectedReceivers();
    }

    private async updateMessages() {
        if (this.curTab === 0) {
            if (this.statusMessages === 0) {
                await this.loadReceivedMessages();
            } else if (this.statusMessages === 1) {
                await this.loadUnreadMessages();
            } else {
                console.log("Неизвестный статус: ", this.statusMessages);
            }
        } else if (this.curTab === 1) {
            await this.loadSendedMessages();
        }
    }

    resetDates() {
        this.startDate = new Date(
            new Date().setMonth(new Date().getMonth() - 1)
        );
        this.endDate = new Date();
    }

    public async updateStartDate(newDate: Date) {
        this.startDate = newDate;
        await this.updateMessages();
    }

    public async updateEndDate(newDate: Date) {
        this.endDate = newDate;
        await this.updateMessages();
    }

    get userId() {
        return store.getters.user_uuid;
    }

    async handleSetSendEmail() {
        try {
            const emailData = {
                user_id: this.userId,
                vals: [{ item: this.isSendEmail }],
            };
            const response = await axios.post(
                "/api/um/save-user-email",
                emailData
            );
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка при настройке отправки уведомлений на почту",
                    error.toString()
                );
            }
        }
    }

    async handleSetSHowPopUp() {
        try {
            const popUpData = {
                user_id: store.getters.user_uuid,
                vals: [{ item: this.isShowPopUp }],
            };
            // this.toggleShowPopUp(popUpData);
            await this.$store.dispatch(
                "notifications/toggleShowPopUp",
                popUpData
            );
        } catch (error) {
            if (error instanceof Error) {
                error.toString();
            }
        }
    }

    private async getEmailSubscription() {
        try {
            const response = await axios.get(
                "/api/um/user-email/" + this.userId
            );
            this.isSendEmail = response.data[0];
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса статуса подписки уведомлять на почту",
                    error.toString()
                );
            }
        }
    }

    public async getPopUpSubscription() {
        try {
            await this.$store.dispatch("notifications/fetchStatusShowPopUp");
        } catch (error) {
            if (error instanceof Error) {
                this.makeToast(
                    "danger",
                    "Ошибка запроса статуса подписки к всплывающим оповещениям",
                    error.toString()
                );
            }
        }
    }

    getRowClass(item: IncomeMessages): string {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0); // Сброс времени на 00:00:00
        const dedlineDate = item.dedline
            ? new Date(item.dedline.split(".").reverse().join("-"))
            : null;

        if (item.status !== 4) {
            if (dedlineDate && dedlineDate > currentDate) {
                return "bg-green";
            } else if (dedlineDate && dedlineDate <= currentDate) {
                return "bg-red";
            } else {
                return "bg-green";
            }
        }
        return "bg-gray";
    }

    infoNotificate() {} /* тут будет метод для выгрузки инструкции пользователя */

    public async mounted() {
        await this.loadReceivedMessages();
        await this.loadSendedMessages();
    }

    public created() {
        this.startDate = new Date(this.startDate);
        this.endDate = new Date(this.endDate);

        this.getEmailSubscription();
        this.getPopUpSubscription();

        this.$watch("curTab", () => {
            this.resetDates();
            this.updateMessages();
        });
        this.$watch("statusMessages", () => {
            this.updateMessages();
        });
    }

    truncateText(text: string | undefined, length: number): string {
        if (!text) return "";
        return text.length > length ? text.substring(0, length) + "..." : text;
    }

    setUpperCase(str: string): string {
        if (!str) return str;
        return str
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    }

    get selectedUsername() {
        const uniqueUsersMap = new Map<string, IReceiver>();

        this.selectedIds.forEach((id) => {
            const user = this.receivers.find(
                (receiver) => receiver.id.toString() === id
            );
            if (user) {
                uniqueUsersMap.set(user.id.toString(), user);
            }
        });
        return Array.from(uniqueUsersMap.values()).map((user) => ({
            id: user.id,
            lastName: user.lastName,
            firstName: user.firstName,
        }));
    }

    get filteredReceivers(): IReceiver[] {
        const searchFIO = this.searchName.toLowerCase().trim();
        const filteredUniqueUsersMap = new Map<string, IReceiver>();

        const searchFiltered = this.receivers.filter((receiver) => {
            const matchesFIO =
                receiver.lastName?.toLowerCase().includes(searchFIO) ||
                receiver.firstName?.toLowerCase().includes(searchFIO) ||
                receiver.abp?.toLowerCase().includes(searchFIO) ||
                receiver.gu?.toLowerCase().includes(searchFIO) ||
                receiver.kgkp?.toLowerCase().includes(searchFIO) ||
                receiver.region?.toLowerCase().includes(searchFIO);
            return matchesFIO;
        });

        const isFiltered =
            this.selectAll ||
            this.selectGu ||
            this.selectMainGu ||
            this.selectKgkp ||
            this.selectSupport;

        const result = isFiltered
            ? searchFiltered.filter((receiver) => {
                  if (this.selectSupport && receiver.isSupport) return true;
                  if (!receiver.isSupport) {
                      if (this.selectAll) return true;
                      if (this.selectGu && receiver.gu) return true;
                      if (this.selectMainGu && receiver.main) return true;
                      if (this.selectKgkp && receiver.kgkp) return true;
                  }
                  return false;
              })
            : searchFiltered;

        // Добавляем только уникальных пользователей по userId
        result.forEach((receiver) => {
            if (!filteredUniqueUsersMap.has(receiver.id.toString())) {
                filteredUniqueUsersMap.set(receiver.id.toString(), receiver);
            }
        });
        return Array.from(filteredUniqueUsersMap.values());
    }

    async updateSelectedReceiverIds() {
        const uniqueIdsSet: Set<string> = new Set(this.selectedIds);
        const anyFilterActive =
            this.selectAll ||
            this.selectGu ||
            this.selectMainGu ||
            this.selectKgkp ||
            this.selectSupport;

        this.receivers.forEach((receiver) => {
            const isMatch = this.filteredReceivers.includes(receiver);

            if (anyFilterActive) {
                if (isMatch) {
                    receiver.check = this.selectedIds.includes(
                        receiver.id.toString()
                    );
                    if (receiver.check) {
                        uniqueIdsSet.add(receiver.id.toString());
                    }
                } else {
                    receiver.check = false;
                }
            } else {
                receiver.check = this.selectedIds.includes(
                    receiver.id.toString()
                );
                if (receiver.check) {
                    uniqueIdsSet.add(receiver.id.toString());
                }
            }
        });

        this.selectedIds = Array.from(uniqueIdsSet);
    }

    removeSelectedUser(userId: string) {
        this.selectedIds = this.selectedIds.filter(
            (id) => id !== userId.toString()
        );
        this.receivers.forEach((user) => {
            if (user.id.toString() === userId) {
                user.check = false;
            }
        });
        this.updateSelectedReceiverIds();
    }

    resetSelectedReceivers() {
        this.receivers.forEach((user: IReceiver) => {
            user.check = false;
        });
        this.selectAll = false;
        this.selectAllFiltered = false;
        this.selectGu = false;
        this.selectMainGu = false;
        this.selectKgkp = false;
        this.selectSupport = false;
        this.selectedIds = [];
        this.searchName = "";
        // this.searchAbp = "";
        // this.searchKgkp = "";
        // this.searchGu = "";
        // this.searchRegion = "";
        this.updateSelectedReceiverIds();
    }

    async handleCheckboxChange(receiver: any) {
        receiver.check = !receiver.check;
        if (!receiver.check) {
            this.selectedIds = this.selectedIds.filter(
                (id) => id !== receiver.id.toString()
            );
        } else {
            this.selectedIds.push(receiver.id.toString());
        }
        const allFilteredSelected = this.filteredReceivers.every(
            (receiver) => receiver.check
        );
        if (!allFilteredSelected) {
            this.selectAllFiltered = false;
        }

        await this.updateSelectedReceiverIds();
    }

    async handleSelectAll() {
        this.selectAll = !this.selectAll;
        this.selectAllFiltered = false;
        if (!this.selectAll) {
            this.resetFilter("allUser");
        }
        await this.updateSelectedReceiverIds();
    }

    async handleSelectGu() {
        this.selectGu = !this.selectGu;
        this.selectAllFiltered = false;
        if (!this.selectGu) {
            this.resetFilter("gu");
        }
        await this.updateSelectedReceiverIds();
    }

    async handleSelectMainGu() {
        this.selectMainGu = !this.selectMainGu;
        this.selectAllFiltered = false;
        if (!this.selectMainGu) {
            this.resetFilter("mainGu");
        }
        await this.updateSelectedReceiverIds();
    }

    async handleSelectKgkp() {
        this.selectKgkp = !this.selectKgkp;
        this.selectAllFiltered = false;
        if (!this.selectKgkp) {
            this.resetFilter("kgkp");
        }
        await this.updateSelectedReceiverIds();
    }

    async handleSelectSupport() {
        this.selectSupport = !this.selectSupport;
        this.selectAllFiltered = false;
        if (!this.selectSupport) {
            this.resetFilter("support");
        }
        await this.updateSelectedReceiverIds();
    }

    async resetFilter(filterType: string) {
        this.receivers.forEach((receiver) => {
            const matchesFilter = this.checkFilterMatch(receiver, filterType);
            if (!matchesFilter) {
                receiver.check = false;
            } else {
                receiver.check = this.selectedIds.includes(
                    receiver.id.toString()
                );
            }
        });
        await this.updateSelectedReceiverIds();
    }

    checkFilterMatch(receiver: IReceiver, filterType: string): boolean {
        switch (filterType) {
            case "gu":
                return this.selectGu && receiver.gu !== undefined;
            case "mainGu":
                return this.selectMainGu && receiver.main !== undefined;
            case "kgkp":
                return this.selectKgkp && receiver.kgkp !== undefined;
            case "support":
                return this.selectSupport && receiver.isSupport;
            case "allUser":
                return (
                    this.selectAll &&
                    receiver.gu !== undefined &&
                    receiver.main !== undefined &&
                    receiver.kgkp !== undefined &&
                    !receiver.isSupport
                );
            default:
                return false;
        }
    }

    async toggleSelectFiltered() {
        this.selectAllFiltered = !this.selectAllFiltered;

        await this.updateReceiversChunked((receiver: any) => {
            if (this.selectAllFiltered) {
                receiver.check = true;
                if (!this.selectedIds.includes(receiver.id.toString())) {
                    this.selectedIds.push(receiver.id.toString());
                }
            } else {
                receiver.check = false;
                this.selectedIds = this.selectedIds.filter(
                    (id) => id !== receiver.id.toString()
                );
            }
        });

        await this.updateSelectedReceiverIds();
    }

    async updateReceiversChunked(updateFunction: any) {
        const chunkSize = 100;
        for (let i = 0; i < this.filteredReceivers.length; i += chunkSize) {
            const chunk = this.filteredReceivers.slice(i, i + chunkSize);
            chunk.forEach(updateFunction);
            await this.$nextTick();
        }
    }

    handleClose() {
        this.showAllNames = false;
        const modalRef = this.$refs.modalRef as Vue & { hide: () => void };
        if (modalRef && typeof modalRef.hide === "function") {
            modalRef.hide();
        }
    }

    handleCloseAddModal() {
        const modalRef = this.$refs.modalRef as Vue & { hide: () => void };
        if (modalRef && typeof modalRef.hide === "function") {
            modalRef.hide();
        }
        this.resetNewMessage();
    }

    makeToast(variant: string | undefined, tostbody: any, title: string) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: "b-toaster-top-center",
            autoHideDelay: 2000,
            solid: true,
            appendToast: true,
        });
    }
}
